.convert .header {
  flex: 3;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.convert.progress {
  margin: 0 0 0 15px;
}

.converter-box {
  min-height: 350px;
}

.converter-box .status-headline {
  font-style: italic;
  text-transform: uppercase;
  font-size: 1.1em;
}

.converter.fas {
  color: #009900;
  font-size: 10px;
}

.converter-table td.pt {
  width: 80px;
}