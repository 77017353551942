ul.collection-tree {
  margin: 0 5px;
}
.collection-entry {
  padding: 3px 5px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.collection-entry:hover {
  background-color: #ebfffc;
  color: #00947e;
}

.collection-entry .button-group {
  visibility: hidden; 
}

.collection-entry.open .button-group {
  visibility: visible; 
}