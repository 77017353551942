.plus-row-button {
  margin: 0 15px 0 58px;
  min-width: 98px;
}

.minus-row-button {
  margin: 0 15px;
  min-width: 98px;
}

.song-table-wrapper {
  overflow-x: auto;
}

.spacer { 
  width: 80px;
}

.stats td {
  padding: 3px;
}

.popup-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.popup-input-container button {
  margin: 0 5px;
}