
.update-table .table-wrapper { max-height: 700px; overflow: scroll; }

.update-table  table {
  position: relative; border: 1px solid #ddd; 
  border-collapse: collapse;
}

.update-table td { border: 1px solid #ddd; text-align: left; }

.update-table th {
  white-space: nowrap; background-color: #eee;
  position: sticky; top: 0px; padding: 3px 9px; font-size: 15px;
}

.update-table th:first-of-type { left: 0; z-index: 9999; }
.update-table tbody tr td:first-of-type {
  background-color: #eee;
  padding: 3px 6px; position: sticky;
  left: 1px; text-align: left; white-space: nowrap;
  font-size: 8px; font-weight: bold; z-index: 9999;
  vertical-align: middle; margin: 34px 2px;
}

input.spreadsheet { border: none; width: 100%; }
.popup {
  position: fixed; bottom: 0; top:0; right: 0;
  padding: 80px 8px; z-index: 999; transition: 0.3s;
}

.popup.open { width:70%; background-color: rgba(90,90,90, 0.75) }
.popup.closed { width:0%;  background-color: rgba(255,255,255, 0) }

.update-file td, .update-file th { font-size: 10px; }