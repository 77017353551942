.floating-new-wrapper {
  position: fixed;
  right: 8px;
  width: 50%;
  bottom: 8px;
  padding: 0 35px;
  z-index: 99999;
}

.add-icon {
  font-size: 17px;
}