.viewer .please-wait {
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewer .table-container {
  font-size: 9px;
}

.viewer .lines {
  margin: 0 15px;
}

.viewer .field.has-addons {
  margin: 0 15px;
}