.currency { margin: 0 8px; }
.sub-catalog { margin: 4px 0; }

.currency-summary {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.output-currency-summary {
  margin-left: 35px;
}

td.table-number {
  text-align: right !important;
}