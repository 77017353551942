.drop-zone-sources {
  height: 98px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border: 3px dotted #ccc;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}