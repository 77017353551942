.table-container {
  overflow-y: auto;
}

.sql-table {
  font-size: 10px;
}

.master-wrapper button.right-align { float: right; }
.master-wrapper .headline { font-weight: bold; margin: 0 0 15px 0; }

.currency-box {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  flex-wrap: wrap;
}
.currency-box .field {
  margin: 15px 15px 15px 0;
  max-width: 250px;
  padding: 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.database-clear {
  margin: 35px 0 0 0;
  font-size: 10px;
}