.physical-entry {
  padding: 3px 5px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.button-group.folder-buttons {
  margin: 0 5px;
}