.derived-header {
  margin: 4px;
  position: relative;
  cursor: pointer;
}

tr.date-header {
  cursor: pointer;
}

div.event-calculated {
  margin: 0 0 5px 0;
}
div.event-calculated i {
  margin: 0 0 0 5px;
  color: #00aa00;
}

.ter-col-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.parser-wrapper {
  flex: 3;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.parser-item {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
}

.parser-item .delete {
  visibility: hidden;
}

.parser-item:hover .delete {
  visibility: visible;
}

.headline-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 5px;
}
.headline-wrapper button {
  margin: 0 35px;
}
