.file-entry {
  position: relative;
  margin: 0 0 0 15px;
  padding: 3px 5px;
  border-radius: 8px;
  font-size: 11px;
  cursor: pointer;
}

.file-entry:hover {
  font-style: italic;
}

.file-file {
  display: inline;
  size: 8px;
}

.file-size {
  margin: 0 2px;
  padding: 0 2px;
  display: inline;
  font-size: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #efefef;
}

.file-ref {
  margin: 0 2px;
  padding: 0 2px;
  display: inline;
  font-size: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #ebfffc;
  color: #00947e;
}
.file-cut {
  margin: 0 2px;
  padding: 0 2px;
  display: inline;
  font-size: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fffbeb;
  color: #947600;
}

.file-duplicate {
  margin: 0 2px;
  padding: 2px;
  display: inline;
  font-size: 8px;
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: #700;
  color: #fff;
}

.filepic {
  padding: 3px 8px;
  margin: 0 8px 0 0;
  border-radius: 35px;
  border: 1px solid #333;
}

.file-entry .delete {
  position: absolute;
  right: 0; 
  visibility: hidden;
}

.file-entry:hover .delete {
  visibility: visible;
}