.sbtag {
  margin: 5 0px;
}
.sbpick {
  display: inline;
  margin: 5 0px;
}
.td-header {
  cursor: pointer;  
}
.td-header span.header-header {
  font-size: 17px;
  font-weight: bold;
}
.data-sample {
  margin: 0 4px;
}
.secondary-royalty {
  margin: 5px 0;
}
.canonical-header {
  margin: 4px;
  position: relative;
  cursor: grab;
}

.header-header {
  margin: 4px;
  position: relative;
  cursor: pointer;
}

div.headers {
  padding-bottom: 90px;
}


div.header-sample { 
  padding: 5px;
  white-space: normal;
  font-size: 10px;
}

.box .unset-header { 
  float: right;
}

.canonicals-hover {
  position: fixed;
  left: 5px;
  top: 0px;
  bottom: 0px;
  width: 20%;
  background-color: rgba(0.3, 0.3, 0.3, 0.7);
  overflow: scroll;
  padding: 8px;
  z-index: 9999;
  display: none;
}
.canonicals-hover.hover {
  display: block;
}


.delete.right {
  float: right;
}

.canonicals-tab {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  left: -35px;
  border-radius: 70px;
  top: 50%;
  width: 100px;
  height: 100px;
  margin-top: -35px;
  background-color: rgba(0.3, 0.3, 0.3, 0.7);
  cursor: pointer;
}

.canonicals-tab.hover {
  display: none;
}

.canonicals-tab i {
  color: #777;
  margin-right: 15px;
  font-size: 35px;
}
