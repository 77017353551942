.song-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.song-wrapper span {
  margin-right: 5px;
}

.song-wrapper div.tag {
  margin: 0 10px 0 5px;
}